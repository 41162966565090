import core from '@/admin/core';
import Axios from 'axios';

const download = async (path: string) => {
  const result = await core.httpClient.get(`/admin/public/assets/downloadSignedUrl?path=${path}`);
  return result.data.signedUrl;
};

const upload = async (file: File) => {
  const result = await core.httpClient.get(`/admin/public/assets/uploadSignedUrl?filename=${file.name}`);
  await Axios({
    method: 'put',
    url: result.data.signedUrl,
    headers: {
      'Content-Type': file.type || 'application/octet-stream',
    },
    data: file,
  });
  return result.data.filePath;
};

const resizeImage = async (path: string) => {
  const result = await core.httpClient.post('/admin/public/assets/resize', { path });
  return result.data.isResized as boolean;
};

export { download, upload, resizeImage };
